<template>
  <section id="WaveHeader">
    <v-card
      style="background: linear-gradient(rgba(157, 0, 255, 1), rgba(73, 2, 124,1))"
      elevation="0"
    >
      <base-img
        :min-height="minHeight"
        :src="require('@/assets/miniweb/wave/miniweb_stylewave_experience_v01.png')"
        class="white--text"
      >
        <v-responsive
          class="ma-auto"
          max-width="900"
          width="100%"
        >
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <!-- DESKTOP -->
              <div
                id="MiniWeb-StyleWave-Experience-line1"
                class="text-left hidden-sm-and-down mt-md-16 pt-md-16 text-md-h4"
              >
                <div
                  class="mt-md-16 pt-md-16  text-subtitle-2 text-uppercase"
                  v-html="'Voici les travaux que nous avons effectués dans le passé'"
                />
                <div
                  v-html="'Vos expériences'"
                />
                <br>
              </div>
              <!-- MOBILE PHONE-->
              <div
                id="MiniWeb-StyleWave-Hearder-line1"
                class="text-center hidden-md-and-up mt-16 pt-12 text-h5 black--text"
              >
                <div
                  class="mt-md-16 pt-md-16 text-subtitle-2 text-uppercase"
                  v-html="'Voici les travaux que nous avons effectués dans le passé'"
                />
                <div
                  v-html="'Vos expériences'"
                />
                <br>
              </div>
            </v-col>
          </v-row>
        </v-responsive>
        <v-row
          align="start"
          justify="space-around"
        >
          <v-col
            v-for="experience in experiences"
            :key="experience.title"
          >
            <miniweb-card-experience
              :color="experience.color"
              :title="experience.title"
              :description="experience.description"
              :mdi="experience.mdi"
            />
          </v-col>
        </v-row>
      </base-img>
    </v-card>
    <v-hover v-slot="{ hover }">
      <v-card
        style="background: white"
        elevation="0"
      >
        <v-card-title class="justify-center text-h5 text-uppercase">Autres specialités {{ hoverTriggerfct(hover) }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              v-for="specialty in otherSpecialties"
              :key="specialty.title"
            >
              <v-avatar
                class="mx-2"
                size="128"
                :color="specialty.color"
              >
                <v-row
                  align="center"
                  justify="center"
                >
                  <v-col>
                    <v-icon dark>
                      {{ specialty.mdi }}
                    </v-icon>
                    <div
                      class="mt-3 white--text"
                      v-html="specialty.title"
                    />
                  </v-col>
                </v-row>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-hover>
    <v-card
      style="background: white"
      elevation="0"
    >
      <v-card-text>
        <miniweb-auto-slider
          :parent-trigger="hoverTrigger"
        />
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
  export default {
    name: 'SectionWaveExperiences',

    data: () => ({
      hoverTrigger: false,

      experiences: [
        {
          color: 'rgba(157, 0, 255, 1)',
          title: 'Chantier 1',
          description: 'Small plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.',
          mdi: 'mdi-call-split',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: 'Chantier 2',
          description: 'Better plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: 'Chantier 3',
          description: 'Better plates, salads & sandwiches - an intimate setting with 12 indoor seats plus patio seating.',
          mdi: 'mdi-email',
        },
      ],
      otherSpecialties: [
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Maison<br>Individuelle</b>',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Electricity</b>',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Salle de bain</b>',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Carrelage</b>',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Plomberie</b>',
          mdi: 'mdi-email',
        },
        {
          color: 'rgba(157, 0, 255, 1)',
          title: '<b>Platrier</b>',
          mdi: 'mdi-email',
        },
      ],
    }),
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      hoverTriggerfct (hover) {
        if (hover) {
          this.hoverTrigger = true
          // console.log(hover)
        }
        return ''
      },
    },
  }
</script>
